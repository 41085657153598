import React from 'react'
import { Button, ButtonProps } from '../../../components/Button'

interface StatusChangeButtonProps {
  changeStatus: () => void
}

export const StatusChangeButton: React.FunctionComponent<ButtonProps & StatusChangeButtonProps> = ({
  changeStatus,
  children,
  category,
  label,
}) => {
  return (
    <Button category={category} size="l" label={label} onClick={changeStatus}>
      {children}
    </Button>
  )
}
