import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React, { useCallback, useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'

import { towingServiceTypeOptions } from '../../../common/options'
import { CustomerType, Maybe, TowingRecordPaymentType, TowingRecordStatus, TowingRecordType, TypeIdentifier } from '../../../common/types'
import { Button } from '../../../components/Button'
import { ButtonColumn, ButtonRow, FormPadder, Page, SimpleRow } from '../../../components/layout'
import { Text } from '../../../components/layout'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { Modal } from '../../../components/Modal'
import { Navigate } from '../../../components/NavigateTo'
import { setErrorNotification, setSuccessNotification } from '../../../components/notification'
import { Loading } from '../../../components/responses'
import { setErrorNotifications, setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../components/router'
import { initialSosServiceOrder } from '../../../components/SosSubForm/types'
import { maybeStopTrackingRecord } from '../../../components/TrackedJobs/util'
import { persistentDelete, persistentSave } from '../../../util/localStorage/localStorage'
import { translateInputOptions } from '../../../util/translateInputOptions'
import { EditCustomer } from '../../Customer/EditCustomer'
import { CustomerFormValues } from '../../Customer/types'
import { DispatchStore, StateStore } from '../../StoreProvider'
import { STORED_TOWING_RECORD, handleNetworkError } from '../../StoreProvider/OfflineMode'
import { shouldDisableOperator } from '../../TowingOrderPage/EditTowingOrder/EditTowingOrderForm'
import { BillingConfirmation } from '../components/billing/BillingConfirmation'
import { shouldShowBillingConfirmation } from '../components/billing/BillingConfirmation/shouldShowBillingConfirmation'
import { getInitialValues } from './initialValues'
import { CONVERT_TO_SOS_RECORD, EDIT_TOWING_RECORD } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { ConvertToSosRecordResponse, EditTowingRecordResponse } from './mutation/types'
import { TowingRecordFormFields } from './TowingRecordFormFields'
import { Billing, EditTowingRecord, EditTowingRecordFormValues } from './types'
import { validate } from './validate'

interface EditTowingRecordProps {
  towingRecord: EditTowingRecord
  paymentType: Maybe<TowingRecordPaymentType>
  scrollTo: string | undefined
}

const convertModalMessage = `Täydennä puuttuvat tiedot. Kun kaikki tiedot on täytetty ja painat tallenna, niin työ muunnetaan SOS työksi.Tallennuksen jälkeen täytä reittipisteet, sinut ohjataan ensimmäiselle reitille automaattisesti.`

const EditTowingRecordPageIntl: React.FunctionComponent<EditTowingRecordProps & InjectedIntlProps> = ({
  intl,
  towingRecord,
  paymentType,
  scrollTo,
}) => {
  const [showCustomerEditForm, setShowCustomerEditForm] = useState<boolean>(false)
  const [showConvertToSosModal, setShowConvertToSosModal] = useState<boolean>(false)
  const [serviceOrderDraft, setServiceOrderDraft] = useState<boolean>(false)
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const [displayConfirmationPage, setShowConfirmationPage] = useState<boolean>(false)

  const { state } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)

  const [convertToSosRecord, { loading }] = useMutation<ConvertToSosRecordResponse>(CONVERT_TO_SOS_RECORD, {
    onCompleted({ convertToSosRecord }) {
      if (convertToSosRecord.__typename === 'ConvertToSosRecordSuccess') {
        setSuccessNotification(
          formatMessage(messages.toSosRecordSuccessTitle),
          formatMessage(messages.toSosRecordSuccessMessage)
        )
        persistentDelete(STORED_TOWING_RECORD)
        historyPush(`/towing-record/${towingRecord.id}/start`)
        return
      }
      setErrorNotifications({ data: convertToSosRecord })
    },
    onError(error) {
      setFatalErrorNotification(error.message)
    },
  })

  const [editTowingRecord, mutationResult] = useMutation<EditTowingRecordResponse>(EDIT_TOWING_RECORD, {
    onCompleted({ editTowingRecord }) {
      if ('towingRecord' in editTowingRecord) {
        maybeStopTrackingRecord(editTowingRecord.towingRecord)
      }
      if (editTowingRecord.__typename === 'EditTowingRecordSuccess') {
        if (shouldShowBillingConfirmation(editTowingRecord.towingRecord.billing)) {
          setShowConfirmationPage(true)
          return
        }

        historyPush(`/towing-record/${towingRecord.id}`)
        setSuccessNotification(
          formatMessage(messages.towingRecordSuccessTitle),
          formatMessage(messages.towingRecordSuccessMessage)
        )
        persistentDelete(STORED_TOWING_RECORD)
        return
      }
      if (editTowingRecord.__typename === 'EditTowingRecordWithBillingUpdateSuccess') {
        const { addBilling } = editTowingRecord

        persistentDelete(STORED_TOWING_RECORD)
        if (addBilling.__typename === 'AddBillingSuccess') {
          setShowConfirmationPage(true)
          return
        }
        setErrorNotification(
          formatMessage(messages.addBillingErrorTitle),
          formatMessage(messages.addBillingErrorMessage)
        )
        return
      }

      setErrorNotifications({ data: editTowingRecord })
    },
    onError() {
      if (!state.offlineMode) {
        return handleNetworkError(dispatch, state.unSavedJobCount + 1)
      }
      handleNetworkError(dispatch, state.unSavedJobCount)
    },
  })

  const handleSubmit = useCallback(
    async (values: EditTowingRecordFormValues) => {
      if (serviceOrderDraft && towingRecord.type !== TowingRecordType.sos) {
        await convertToSosRecord({ variables: toMutationVariables(values, serviceOrderDraft) })
        setServiceOrderDraft(false)
        return
      }
      await editTowingRecord({ variables: toMutationVariables(values) })
    },
    [editTowingRecord, convertToSosRecord, serviceOrderDraft, towingRecord.type]
  )

  const defaultItemsIncludeVat = state.settings ? state.settings.defaultItemsIncludeVat : true
  const initialValues = getInitialValues(towingRecord, defaultItemsIncludeVat, paymentType)
  const agreements = state.settings ? state.settings.agreements : []
  const canConvertToSos =
    state.settings &&
    state.settings.modules &&
    state.settings.modules.sos &&
    towingRecord.typeIdentifier !== TypeIdentifier.lahitapiola &&
    towingRecord.status === TowingRecordStatus.unfinished

  if (agreements.length > 0) {
    initialValues.signature.agreements = agreements.map(a => {
      return {
        id: a.id,
        text: a.text,
        isAgreed: true,
      }
    })
  }

  const translatedServiceTypeOptions = translateInputOptions(towingServiceTypeOptions, formatMessage)
  const billing = getNewBillingData(mutationResult.data)
  const disableOperator = shouldDisableOperator(state.currentUser)

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ values, setValues, setFieldValue, submitForm, dirty }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const setOrderer = (o: CustomerFormValues) => {
          if (!values.orderer) {
            return
          }

          const currentOrdererType = values.orderer.type
          values.orderer = Object.assign({}, o)
          values.orderer.type = currentOrdererType

          if (state.settings && state.settings.notifications.enabled) {
            switch (o.type) {
              case CustomerType.person:
                if (o.personCustomer) {
                  if (o.personCustomer.notificationMethod === 'default') {
                    values.notificationMethod = state.settings.notifications.method
                  } else {
                    values.notificationMethod = o.personCustomer.notificationMethod
                  }
                }
                break
              case CustomerType.company:
                if (o.companyCustomer) {
                  if (o.companyCustomer.notificationMethod === 'default') {
                    values.notificationMethod = state.settings.notifications.method
                  } else {
                    values.notificationMethod = o.companyCustomer.notificationMethod
                  }
                }
                break
            }
          }

          setValues(values)
        }

        const hasFormChanged = ()=>{
          return dirty || values.billing.paymentDetails.id === null
        }

        const setOrdererFromTrafi = (o: CustomerFormValues) => {
          values.orderer = Object.assign({}, o)
          setValues(values)
        }

        const save = () => {
          persistentSave('towingRecord', values)

          if (values.sosServiceOrder && serviceOrderDraft) {
            const { coords: fromCoords } = values.from
            // const { coords: toCoords, address } = values.to
            const { insuranceCompany, deliverable, cause } = values.sosServiceOrder
            let errFields = ''
            let coordsErr = false

            if (!insuranceCompany) {
              errFields += 'Vakuutusyhtiö'
            }

            if (!deliverable) {
              errFields += errFields.length > 0 ? `, Arvioitu toimenpide` : 'Arvioitu toimenpide'
            }

            if (!cause) {
              errFields += errFields.length > 0 ? `, Arvioitu vika` : 'Arvioitu vika'
            }

            if (!fromCoords.lat || !fromCoords.long) {
              coordsErr = true
              errFields +=
                errFields.length > 0
                  ? `. Kohteen koordinaatteja ei saatu haettua`
                  : 'Kohteen koordinaatteja ei saatu haettua'
            }

            if (coordsErr) {
              errFields += '. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
            }

            if (errFields.length > 0) {
              return setErrorNotification('Virhe', `Tarkista seuraavat tiedot: ${errFields}`)
            }
          }

          submitForm()
        }

        const onSetSosServiceOrder = () => {
          const t = 'sosServiceOrder'
          values[t] = Object.assign({}, initialSosServiceOrder)
          setValues(values)
        }

        return (
          <Page noPadding>
            <Loading loading={mutationResult.loading || loading} />
            {showCustomerEditForm && values.orderer && (
              <EditCustomer
                orderer={values.orderer}
                onClose={() => setShowCustomerEditForm(false)}
                onUpdate={orderer => {
                  setOrderer(orderer)
                  setShowCustomerEditForm(false)
                }}
              />
            )}

            {showConvertToSosModal && (
              <Modal
                label="Muunna SOS työksi"
                message={convertModalMessage}
                close={() => setShowConvertToSosModal(false)}
                onConfirm={() => {
                  setServiceOrderDraft(true)
                  onSetSosServiceOrder()
                }}
                confirmLabel="Muunna SOS työksi"
                closeLabel="Peruuta"
              />
            )}

            {billing && displayConfirmationPage ? (
              <BillingConfirmation
                billing={billing}
                towingRecordId={towingRecord.id}
                onConfirmBilling={() => {
                  historyPush(`/towing-record/${towingRecord.id}`)
                  setSuccessNotification(
                    formatMessage(messages.billingConfirmationTitle),
                    formatMessage(messages.billingConfirmationMessage)
                  )
                }}
                close={() => {
                  historyPush(`/towing-record/${towingRecord.id}`)
                  setSuccessNotification(
                    formatMessage({
                      id: 'towingRecord.edit.success.notification.title',
                      defaultMessage: 'Changes saved',
                    }),
                    formatMessage({
                      id: 'towingRecord.edit.success.notification.message',
                      defaultMessage: 'Changes to towing record has been saved',
                    })
                  )
                }}
                continueEditing={() => {
                  setShowConfirmationPage(false)
                  historyPush(`/towing-record/${towingRecord.id}/edit`)
                }}
              />
            ) : (
              <Form>
                <SimpleRow
                  margin="0.5rem 1rem 0.5rem 0.5rem"
                  mobileMargin="0.5rem 0.5rem 0.5rem 0"
                  justify="space-between"
                >
                  <IconColumn
                    direction="back"
                    label="Takaisin"
                    onClick={() => historyPush(`/towing-record/${towingRecord.id}`)}
                  />
                  <HeadingColumn>
                    <Text fontWeight="700" size="big">{`Kuormakirja: ${towingRecord.recordNumber}`}</Text>
                  </HeadingColumn>
                </SimpleRow>
                <TowingRecordFormFields
                  formatMessage={formatMessage}
                  setValues={setValues}
                  setOrderer={setOrderer}
                  values={values}
                  setValue={setValue}
                  hasFormChanged={hasFormChanged}
                  translatedServiceTypeOptions={translatedServiceTypeOptions}
                  setShowCustomerEditForm={setShowCustomerEditForm}
                  initialTowingRecordValues={initialValues}
                  scrollTo={scrollTo}
                  disableOperator={disableOperator}
                  isServiceOrderDraft={serviceOrderDraft}
                  vehicleInfoCheck={towingRecord.vehicleInfoCheck}
                  setOrdererFromTrafi={setOrdererFromTrafi}
                />
                <FormPadder>
                  <ButtonRow justify="space-between">
                    <ButtonColumn>
                      <Button category="save" size="l" label="Tallenna" onClick={save} />
                    </ButtonColumn>
                    {towingRecord.type === TowingRecordType.default && canConvertToSos && !serviceOrderDraft && (
                      <ButtonColumn>
                        <Button
                          category="save"
                          size="l"
                          label="Muunna SOS työksi"
                          onClick={() => setShowConvertToSosModal(true)}
                        />
                      </ButtonColumn>
                    )}
                  </ButtonRow>
                </FormPadder>
                <ScrollToError />
              </Form>
            )}
          </Page>
        )
      }}
    />
  )
}

function getNewBillingData(response?: EditTowingRecordResponse): Billing | null {
  if (!response) {
    return null
  }
  if (response.editTowingRecord.__typename === 'EditTowingRecordWithBillingUpdateSuccess') {
    const billing = response.editTowingRecord.addBilling
    if (billing.__typename === 'AddBillingSuccess') {
      return billing.billing
    }
    return null
  }
  if (response.editTowingRecord.__typename === 'EditTowingRecordSuccess') {
    if (response.editTowingRecord.towingRecord.billing) {
      return response.editTowingRecord.towingRecord.billing[0]
    }
    return null
  }
  setErrorNotifications({ data: response.editTowingRecord })
  return null
}

const messages = defineMessages({
  towingRecordSuccessTitle: {
    id: 'towingRecord.edit.success.notification.title',
    defaultMessage: 'Changes saved',
  },
  towingRecordSuccessMessage: {
    id: 'towingRecord.edit.success.notification.message',
    defaultMessage: 'Changes to towing record has been saved',
  },
  addBillingErrorTitle: {
    id: 'towingRecord.add_billing.error.notification.title',
    defaultMessage: 'Unnable to save billing info',
  },
  addBillingErrorMessage: {
    id: 'towingRecord.add_billing.error.notification.message',
    defaultMessage: 'There was an error when updating billing information',
  },
  billingConfirmationTitle: {
    id: 'towingRecord.billing.confirm.notification.title',
    defaultMessage: 'Billing confirmed',
  },
  billingConfirmationMessage: {
    id: 'towingRecord.billing.confirm.notification.message',
    defaultMessage: 'Towing record saved and billing confirmed',
  },
  toSosRecordSuccessTitle: {
    id: 'towingRecord.convert.success.notification.title',
    defaultMessage: 'Changes saved',
  },
  toSosRecordSuccessMessage: {
    id: 'towingRecord.convert.success.notification.message',
    defaultMessage: 'Changes to towing record has been saved',
  },
})

export const EditTowingRecordPage = injectIntl(EditTowingRecordPageIntl)

export const IconColumn = styled(Navigate)`
  display: flex;
  // width: calc(50% - 0.5rem);
  justify-content: start;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    // width: 50%;
  }
`

export const HeadingColumn = styled.div`
  display: flex;
  // width: calc(50% - 1rem);
  justify-content: flex-end;
  // margin-right: 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    // margin-right: 0.5rem;
    // width: calc(50% - 0.5rem);
  }
`
