import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { removeStoredBackendNotifications } from '../../graphql-client/storedState'
import { BackendNotification, BackendNotificationAction } from '../../util/notifications/types'
import { Button } from '../Button'
import { Column, Row, Section, SectionColumn, Text } from '../layout'

interface BackendNotificationProps {
  notifications: BackendNotification[]
}

const BackendNotificationList: FunctionComponent<BackendNotificationProps> = ({ notifications }) => {
  return (
    <>
      {notifications.length > 0 &&
        notifications.map((n: BackendNotification, i) => <ShowNotification notification={n} key={i} />)}
    </>
  )
}

interface ShowNotificationProps {
  notification: BackendNotification
}

const ShowNotification: FunctionComponent<ShowNotificationProps> = ({ notification }) => {
  return (
    <>
      <NotificationWrapper>
        {notification.title && (
          <Row>
            <Column>
              <Text size="medium">{notification.title}</Text>
            </Column>
          </Row>
        )}
        <Row>
          <Column>
            {notification.message.split('\\n').map((part, i) => {
              if(!part){
                return <div key={i}><br /></div>
              }
              return <Text size="medium" key={i}>{part}</Text>
            })}
          </Column>
        </Row>
      </NotificationWrapper>
      {notification.actions.length > 0 && <ShowNotificationActions actions={notification.actions} />}
    </>
  )
}

interface ShowNotificationActionsProps {
  actions: BackendNotificationAction[]
}

const ShowNotificationActions: FunctionComponent<ShowNotificationActionsProps> = ({ actions }) => {
  return (
    <>
      <Section columns={actions.length} height="auto" margin="1rem 0" gap="1rem">
        {actions.length > 0 &&
          actions.map((action: BackendNotificationAction, i) => <ShowNotificationAction action={action} key={i} />)}
      </Section>
    </>
  )
}

interface ShowNotificationActionProps {
  action: BackendNotificationAction
}

const ShowNotificationAction: FunctionComponent<ShowNotificationActionProps> = ({ action }) => {
  const handleClick = () => {
    if (action.type === 'close') {
      return removeStoredBackendNotifications()
    }
    window.open(action.url)
  }
  return (
    <>
      <SectionColumn alignItems="center">
        {action.type === 'close' ? (
          <Button
            label={action.label}
            category="cancel"
            maxWidth="100%"
            size="m"
            fontSize="1.25rem"
            onClick={handleClick}
          />
        ) : (
          <Button
            label={action.label}
            category="regular"
            maxWidth="100%"
            size="m"
            fontSize="1.25rem"
            onClick={handleClick}
          />
        )}
      </SectionColumn>
    </>
  )
}

const NotificationWrapper = styled.div`
  margin: 1rem 0 2rem 0;
`

export default BackendNotificationList
