import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getStoredCompanyId, getStoredUserId } from '../../graphql-client/storedState'
import { useSocket } from '../../util/useSocket'
import { Button } from '../Button'
import { useHistoryPush } from '../router'
import styled, { css } from 'styled-components'
import { ModalContainer, ModalHeader, ModalText } from '../layout'
import { ButtonContainer } from '../../containers/TowingRecordPage/components/routes/subforms/styled'
import { Icon } from '../icons/Icon'
import { DispatchStore, StateStore } from '../../containers/StoreProvider'
import { hideSocketModal, setMessageCount, showSocketModal } from '../../containers/StoreProvider/actions'
import uuid from 'uuid'
import { persistentAddMessage, persistentDeleteMessage, persistentGetOrDefault, persistentSave } from '../../util/localStorage/localStorage'


type MsgCategory = 'alert' | 'warning' | 'info' 
type MsgType = 'orderCancelled' | 'recordCancelled' | 'recordChanged' 

export interface IMessageData {
    header: string
    msg: string
    timeStamp: string
    changedData?: RecordChangedData | null
    towingDate?: string
    towingTime?: string
    vehicleMakeAndModel?: string
    from?: string
    to?: string
    orderId?: number
    recordId?: number
    category?: MsgCategory 
    showOld?: boolean
    type: MsgType
    userId: number
    id: string
}

interface RecordChangedData {
    editedBy: string
    values: ChangedDataType[]
}

interface ChangedDataType {
    attr: string | null
    name: string | null
    new: string | null
    old: string | null
}

export const SocketNotificationModal: React.FunctionComponent<any> = () => {

  const {state} = useContext(StateStore)
  const {dispatch} = useContext(DispatchStore);
  const socket = useSocket()
  const userId = getStoredUserId()
  const companyId = getStoredCompanyId()
  const [activePushMessage, setActivePushMessage] = useState<boolean>(false)
  const [messages, setMessages] = useState<IMessageData[]>([]);

  const getUserLocalMessages = useCallback<()=>IMessageData[]>(()=>{
    const allItems = persistentGetOrDefault<IMessageData[]>('socketMessages', []);
    const userItems = allItems.filter((item) => item.userId === userId);
    dispatch(setMessageCount(userItems.length));
    return userItems;
  },[dispatch, userId])

  const deleteUserLocalMessages = useCallback<()=>void>(()=>{
    const items = persistentGetOrDefault<IMessageData[]>('socketMessages', []);
    const filtered = items.filter(item => item.userId !== userId);
    persistentSave('socketMessages', filtered);
    dispatch(setMessageCount(0));
  }, [dispatch, userId])

  const deleteUserMessage = useCallback(function(id: string){
    persistentDeleteMessage(id);
    const items = getUserLocalMessages()
    setMessages(items);
  }, [getUserLocalMessages, setMessages])


  const emptyMessageList = function(){
    setMessages([]);
    deleteUserLocalMessages();
  }

  // const setDummyData = ()=>{
  //   const data: IMessageData[] = [
  //     {
  //       header: 'Työn tiedot muuttuneet (ABC-123)',
  //       msg: 'Kuormakirjan tiedot ovat muuttuneet #244',
  //       timeStamp: '01.05.2023 12:55',
  //       changedData: {
  //         editedBy: 'Patu',
  //         values: [
  //            {
  //             attr: "email",
  //             name: "Sähköposti",
  //             new: "asdasd@asd.asd",
  //             old: "risto.hirvo@innovoice.fi"
  //           },
  //           {
  //             attr: 'lastname',
  //             name: "Sukunimi",
  //             new: "Miettinen", 
  //             old: "test"
  //           }, 
  //           {
  //             attr: "firstname",
  //             name: "Etinimi",
  //             new: "Mikko", 
  //             old: "test"
  //           },
  //           {
  //             attr: "vehicle_type",
  //             name: null,
  //             new: "test", 
  //             old: "Henkilöauto (M1)"
  //           }
  //         ]
  //       },
  //       recordId: 244,
  //       category: 'warning',
  //       showOld: false,
  //       type: 'recordChanged',
  //       userId: 740,
  //       id: 'jke43k'
  //     }, 
  //     {
  //       header: 'Työ peruttu (ABC-123)',
  //       msg: 'SOS perunut tilauksen josta luotu kuormakirja #123',
  //       timeStamp: '01.05.2023 12:55',
  //       towingDate: '21.5.2023',
  //       towingTime: '13:25',
  //       vehicleMakeAndModel: 'Toyota Auris 5d',
  //       from: 'Ristiniementie 26, Espoo',
  //       to: 'Ristiniementie 26, Espoo',
  //       recordId: 123,
  //       category: 'alert',
  //       type: 'recordCancelled',
  //       userId: 740,
  //       id: 'jkklk4'
    
  //     }, 
  //     {
  //       header: 'Työ peruttu (ABC-123)',
  //       msg: 'SOS perunut tilauksen josta luotu kuormakirja #123',
  //       timeStamp: '01.05.2023 12:55',
  //       towingDate: '21.5.2023',
  //       towingTime: '13:25',
  //       vehicleMakeAndModel: 'Toyota Auris 5d',
  //       from: 'Ristiniementie 26, Espoo',
  //       to: 'Ristiniementie 26, Espoo',
  //       orderId: 124,
  //       category: 'info',
  //       type: 'orderCancelled',
  //       userId: 30,
  //       id: '4g3k2'
  //     }, ]

  //     persistentSave('socketMessages', data);
  // }


  useEffect(() => {
    if(!userId || !companyId){
      socket.removeAllListeners()
      socket.close()
      console.log('closing socket connection')
      return
    }
    // setDummyData()
    const items = getUserLocalMessages();
    setMessages(items);
    
    const callback = (category: MsgCategory, type: MsgType,  userId: number, message: string)=>{
      const event: IMessageData = JSON.parse(message)
      console.log('this is the event:', event)
      event.category = category;
      event.type = type;
      event.userId = userId;
      event.id = uuid();

      if(event.changedData){
        event.showOld = false;
      }
      if(event.recordId){
        const localEvent = new CustomEvent<IMessageData>(`record_${event.recordId}:changed`, {detail: event});
        document.dispatchEvent(localEvent);
      }

      persistentAddMessage(event);
      const items = getUserLocalMessages();

      dispatch(showSocketModal());
      setMessages(items)
    }

    socket.on(`user-${userId}.orderCancelled`, function(msg: string){callback('warning', 'orderCancelled', userId, msg)})
    socket.on(`user-${userId}.recordCancelled`, function(msg: string){callback('alert', 'recordCancelled', userId, msg)})
    socket.on(`user-${userId}.recordChanged`, function(msg: string){callback('warning', 'recordChanged', userId, msg)})
    
    return ()=>{
      console.log('UNMOUNTING AND REMOVING SOCKET LISTENERS')
      socket.removeListener(`user-${userId}.orderCancelled`, callback)
      socket.removeListener(`user-${userId}.recordCancelled`, callback)
      socket.removeListener(`user-${userId}.recordChanged`, callback)
    }
  },[userId, companyId, socket, setMessages, setActivePushMessage, dispatch, getUserLocalMessages])


  const historyPush = useHistoryPush()
  const onConfirm = useCallback((index: number)=>{
    const current = messages[index]
    if(current.recordId){
      historyPush(`/towing-record/${current.recordId}`)
      setActivePushMessage(false)
      dispatch(hideSocketModal())
    } 
   
  }, [messages, historyPush, dispatch])

  const onClose = useCallback(()=>{
    setActivePushMessage(false);
    dispatch(hideSocketModal());
  },[dispatch])

  const toggleOldData = useCallback((evt: any, index: number)=>{
    evt.preventDefault();
    setMessages(messages.map((message, ind)=>{
      if(ind === index){
        message.showOld = !message.showOld;
      }
      return message
    }))
  }, [setMessages, messages])

  return <>
    <ModalContainer style={{background: 'white', paddingBottom: '80px', transform: activePushMessage || state.showMessages ? 'none' : 'translate(0, -100vh)' , transition: 'all', transitionDuration: '0.5s'}}>
      <ModalHeader style={{paddingLeft: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <ModalText>Muutoksia tilauksiin</ModalText><Button category='new' size='s' maxWidth='max-content' onClick={emptyMessageList}>Tyhjennä viestit</Button>
      </ModalHeader>
    <SocketMessageContainer>
      {messages.length !== 0 ? 
        messages.map((message, index) => {
          return (
            <SocketMessageListItem key={index}>
                  <MessageHeader category={message.category}><MessageLabel>{message.timeStamp + ' - ' + message.header}</MessageLabel><IconWrapper onClick={()=>{deleteUserMessage(message.id)}}><Icon size="x-small" icon={'close'} /></IconWrapper></MessageHeader>
 
                  {message.type === 'recordChanged' && (
                    <>
                      <ChangedDataContainer 
                        showOld={message.showOld ? message.showOld : false} 
                        data={message.changedData ? message.changedData : null}
                        showOldData={(evt: any) => {toggleOldData(evt, index)}}
                        goToRecord={() => {onConfirm(index)}}
                        recordId={message.recordId}>
                      </ChangedDataContainer>
                    </>
                  )}
                  {message.type !== 'recordChanged' && (
                    <MessageContainer>
                    <>
                      <MessageItemWide><MessageContent>{message.msg}</MessageContent></MessageItemWide>
                      <MessageItem>{'Tilauksen ajankohta: '}</MessageItem>
                      <MessageData>{message.towingDate + ' ' + message.towingTime}</MessageData>
                      <MessageItem>{'Merkki ja malli: '}</MessageItem>
                      <MessageData>{message.vehicleMakeAndModel}</MessageData>
                      <MessageItem>{'Mistä: '}</MessageItem>
                      <MessageData>{message.from}</MessageData>
                      <MessageItem>{'Mihin: '}</MessageItem>
                      <MessageData>{message.to}</MessageData>
                      {message.recordId && <MessageItemWide><MessageWarning>Huom! Työ lopetettava työnäkymässä</MessageWarning></MessageItemWide>}
                    </>
                  {message.recordId && (<MessageButtonItem><Button category={'new'} maxWidth='100%' size='s' onClick={() => {onConfirm(index)}}>{'Siirry työnäkymään'}</Button></MessageButtonItem>)}
                </MessageContainer>
                  )}
            </SocketMessageListItem>
          )
        }) : (<div style={{margin: '4rem 0', fontSize: 'large', fontWeight: 'bold'}}>Ei lukemattomia viestejä</div>)
      }
    </SocketMessageContainer>
    <ButtonContainer floatAtBottomNoMenu>
          <Button category='save' size='m' maxWidth='100%' onClick={onClose}>Sulje</Button> 
    </ButtonContainer>
  </ModalContainer>
  {/* <MessagesDragger onClick={showModalWindow} >{messages.length > 0 ? 'Viestit ('+messages.length+')': 'Viestit'}</MessagesDragger> */}
 </>
}


const ChangedDataContainer: React.FunctionComponent<{data: RecordChangedData | null, showOld: boolean, recordId: number|undefined, showOldData: (evt:any)=>void, goToRecord: ()=>void}> = ({data, recordId, showOldData, goToRecord, showOld = false}) => {
 return (
  <ChangeDataWrapper>
    {data && data.editedBy && (
        <>
          <MessageItemWide><MessageContent>{'Käyttäjä '+data.editedBy+' on muokannut kuormakirjaa seuraavasti:'}</MessageContent></MessageItemWide>
        </>
      )}
    {data && data.values.length > 0 && (
    <>
      {data.values.map((val)=>{
          return (<ChangedDataRow key={uuid()} showOld={showOld} header={val.name ? val.name : ''} updated={val.new} old={val.old} ></ChangedDataRow>)
      })}
      </>

    )}
    {!showOld && (<div style={{fontWeight: 'bold', marginBottom: '1rem', textDecoration: 'underline', color: '#db7d02', cursor: 'pointer'}}><div onClick={showOldData} >Näytä vanhat tiedot</div></div>)}
    {showOld && (<div style={{fontWeight: 'bold', marginBottom: '1rem', textDecoration: 'underline', color: '#db7d02', cursor: 'pointer'}}><div onClick={showOldData} >Piilota vanhat tiedot</div></div>)}
    {recordId && (<MessageButtonItem><Button category={'new'} maxWidth='300px' size='s' onClick={goToRecord}>{'Siirry työnäkymään'}</Button></MessageButtonItem>)}
  </ChangeDataWrapper>
 )
}

const ChangedDataRow: React.FunctionComponent<{showOld: boolean, header: string, updated: string | null, old: string | null}> =  ({showOld, header, updated, old}) => {
return (
      <ItemContainer>
        <MessageItem>{header}</MessageItem>
        <div style={{display: 'flex',  flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start'}}>
          <MessageData>{!updated ? '(tyhjä)' : updated}</MessageData>
          <MessageDataSmall show={showOld}>{!old ? '(tyhjä)' : old }</MessageDataSmall>
        </div>
        </ItemContainer>
  );
}

interface HeaderProps {
  category?: 'alert' | 'warning' | 'info'
}

const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 0rem;
  grid-column-gap: 3rem;
  padding: 1rem 2rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: auto;
}
`
const MessageHeader = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  ${props=> 
    props.category && css`
      background-color: ${props.category === 'alert' ? props.theme.colors.red100 : props.category === 'warning' ? props.theme.colors.yellow100 : props.theme.colors.blue100};
    `}
`
const ItemContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 0rem;
  grid-column-gap: 3rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: auto;
}
`
const ChangeDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 1rem 2rem;
`
const MessageItem = styled.div`
  padding-bottom: 0.2rem
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-weight: bold;
  }
`
const MessageData = styled.div`
  margin-right: 1rem;
  padding-bottom: 0.2rem
`
  
  interface OldDataDivProps {
    show: boolean
  }

const MessageDataSmall = styled.div<OldDataDivProps>`
  padding-bottom: 0.2rem
  ${props=> 
    !props.show && css`
    display: none;
    `}
  color: red;
`
const MessageContent = styled.div`
  padding: 0.2rem 0 1rem 0;
`

const MessageItemWide = styled.div`
  grid-column: span 2;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-column: span 1;
}
`

const MessageButtonItem = styled.div`
  grid-column: span 2;
  padding-top: 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-column: span 1;
}
`

const MessageLabel = styled.div`
  font-size: large;
  font-weight: bold;
`

const MessageWarning = styled.div`
  font-weight: bold;
  padding: 0.8rem 0 0 0;
`

export const SocketMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SocketMessageListItem = styled.div`
  display: flex; 
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 95vw; 
  border: solid 1px #d6d6d6;
  border-radius: 6px;
  box-shadow: -2px -2px 10px #d1d1d1;
  margin: 0.5rem;

`
const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
`
