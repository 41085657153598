import { Field, FormikProps } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { TextField } from '../../field'
import emailValidator from 'email-validator'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  onChange?: (_: any, form: FormikProps<any>) => void
  onBlur?: (form: FormikProps<any>) => void
  type?: string
  toUpperCase?: boolean
  afterRender?: () => void
}

export const TextInput: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  disabled,
  required,
  onChange,
  onBlur,
  afterRender,
  type = 'text',
  toUpperCase = false,
  ...rest
}) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }

      if (value && type === 'email' && !emailValidator.validate(value)) {
        return 'Virheellinen sähköpostiosoite'
      }
      return undefined
    },
    [required, type]
  )

  useEffect(() => {
    if (afterRender) {
      afterRender()
    }
  }, [afterRender])

  return (
    <Field
      {...rest}
      component={TextField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      disabled={disabled}
      onValueChange={onChange}
      type={type}
      toUpperCase={toUpperCase}
      onBlur={onBlur}
    />
  )
}
