import { useQuery } from '@apollo/react-hooks'
import React, { useContext, useEffect } from 'react'
import { setItemGroups, setItems, setSettings, setUserPreferences } from '../StoreProvider/actions'
import { GET_ALL_ITEMS, GET_ITEM_GROUPS, GET_SETTINGS, GET_PREFERENCES } from './queries'
import { ItemGroupsResponse, ItemsResponse, SettingsResponse, GetPreferencesResponse } from './types'
import { DispatchStore, StateStore } from '../StoreProvider'
import { setErrorNotifications, setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import {
  hasStoredItemGroups,
  hasStoredItems,
  hasStoredSettings,
  getStoredItemGroups,
  getStoredItems,
  getStoredSettings,
  storeItemGroups,
  storeItems,
  storeSettings,
  hasStoredUserPreferences,
  getStoredUserPreferences,
  storeUserPreferences,
} from '../../graphql-client/storedState'
import { getStationFilters } from './station'

export const GetParameters: React.FunctionComponent = () => {
  const { dispatch } = useContext(DispatchStore)
  const {
    state: { currentUser, settings },
  } = useContext(StateStore)

  useEffect(() => {
    if (hasStoredItemGroups()) {
      const action = setItemGroups(getStoredItemGroups())

      if (action) {
        dispatch(action)
      }
    }

    if (hasStoredItems()) {
      const action = setItems(getStoredItems())
      if (action) {
        dispatch(action)
      }
    }

    if (hasStoredSettings()) {
      const action = setSettings(getStoredSettings())
      if (action) {
        dispatch(action)
      }
    }

    if (hasStoredUserPreferences()) {
      const action = setUserPreferences(getStoredUserPreferences())
      if (action) {
        dispatch(action)
      }
    }

    // if (hasStoredStaticData()) {
    //   const action = setArcCodes(getStoredStaticData())
    //   if (action) {
    //     dispatch(action)
    //   }
    // }
  }, [dispatch])

  useQuery<ItemGroupsResponse>(GET_ITEM_GROUPS, {
    fetchPolicy: 'network-only',
    skip: hasStoredItemGroups(),
    onCompleted({ itemGroups }) {
      if (itemGroups.__typename === 'ItemGroupsSuccess') {
        console.log('itemGroups', itemGroups.itemGroups)
        storeItemGroups(itemGroups.itemGroups)
        const action = setItemGroups(itemGroups.itemGroups)
        if (action) {
          dispatch(action)
        }
      } else {
        setErrorNotifications({ data: itemGroups })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  useQuery<ItemsResponse>(GET_ALL_ITEMS, {
    fetchPolicy: 'network-only',
    skip: hasStoredItems(),
    onCompleted({ items }) {
      if (items.__typename === 'ItemsSuccess') {
        console.log('itemGroups', items.items)
        storeItems(items.items)
        const action = setItems(items.items)
        if (action) {
          dispatch(action)
        }
      } else {
        setErrorNotifications({ data: items })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  useQuery<SettingsResponse>(GET_SETTINGS, {
    fetchPolicy: 'network-only',
    skip: hasStoredSettings(),
    onCompleted({ settings }) {
      if (settings.__typename === 'GetSettingsSuccess') {
        storeSettings(settings.settings)
        const action = setSettings(settings.settings)
        if (action) {
          dispatch(action)
        }
      } else {
        setErrorNotifications({ data: settings })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  // useQuery<GetStaticDataResponse>(GET_STATIC_DATA, {
  //   fetchPolicy: 'network-only',
  //   skip: hasStoredStaticData(),
  //   onCompleted({ staticData }) {
  //     if (staticData.__typename === 'GetStaticDataSuccess') {
  //       storeStaticData(staticData.arcCodes)
  //       const action = setArcCodes(staticData.arcCodes)
  //       if (action) {
  //         dispatch(action)
  //       }
  //     } else {
  //       setErrorNotifications({ data: staticData })
  //     }
  //   },
  //   onError(err) {
  //     setFatalErrorNotification(err.message)
  //   },
  // })

  useQuery<GetPreferencesResponse>(GET_PREFERENCES, {
    fetchPolicy: 'network-only',
    skip: hasStoredUserPreferences() || !hasStoredSettings(),
    onCompleted({ preferences }) {
      if (preferences.__typename === 'GetPreferencesSuccess') {
        const { id, filters } = preferences.preferences
        const pref = { id, filters: filters ? JSON.parse(filters) : null }
        const formattedFilters = getStationFilters(settings, currentUser ? currentUser.userId : null, pref)

        if (formattedFilters) {
          storeUserPreferences(formattedFilters)

          const filterAction = setUserPreferences(formattedFilters)

          if (filterAction) {
            dispatch(filterAction)
          }
        }
      } else {
        setErrorNotifications({ data: preferences })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  return null
}
