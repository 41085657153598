import { FormattedMessage, MessageValue } from 'react-intl'

import { ShortDate } from '../functions/graphqlApi/types/scalarTypes'

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type Maybe<T> = T | null

export interface Time extends String {
  _time: never
}

export interface Options {
  options: { [key: string]: string }
}

export type FormatMessageType = (
  messageDescriptor: FormattedMessage.MessageDescriptor,
  values?: { [key: string]: MessageValue }
) => string

// Towing order

export enum TowingOrderServiceType {
  TOWING = 'towing',
  ROAD_SERVICE = 'road_service',
  BATTERY_SERVICE = 'battery_service',
  PHONE_SERVICE = 'phone_service',
}

export enum TowingOrderVisibility {
  OPEN = 'open',
  ASSIGNED = 'assigned',
  RESTRICTED = 'restricted',
  SUBCONTRACT = 'subcontract',
}

export enum TowingOrderType {
  default = 'default',
  autoliitto = 'autoliitto',
  call_center = 'call_center',
  www = 'www',
  api = 'api',
  sos = 'sos',
  subcontract = 'subcontract',
}

export enum TowingOrderStatus {
  pending_acceptance = 'pending_acceptance',
  open = 'open',
  accepted = 'accepted',
  assigned = 'assigned',
  towing_record_created = 'towing_record_created',
  cancelled = 'cancelled',
}

// Towing record

export enum TowingRecordPaymentType {
  person = 'person',
  company = 'company',
  insurance_company = 'insurance_company',
  aggregate = 'aggregate',
  cash = 'cash',
  card = 'card',
  define_later = 'define_later',
  settlement = 'settlement',
  autoliitto = 'autoliitto',
}

export enum TowingRecordStatus {
  unfinished = 'unfinished',
  complete = 'complete',
  deleted = 'deleted',
  billing = 'billing',
  aggregate = 'aggregate',
  cancelled = 'cancelled',
}

export enum TowingRecordType {
  default = 'default',
  autoliitto = 'autoliitto',
  sos = 'sos',
}

export enum TowingRecordServiceType {
  TOWING = 'towing',
  ROAD_SERVICE = 'road_service',
  BATTERY_SERVICE = 'battery_service',
  PHONE_SERVICE = 'phone_service',
}

export enum TowingRecordRouteType {
  default = 'default',
  start = 'start',
  arrival = 'arrival',
  finish = 'finish',
  end = 'end',
  finish_breakdown_location = 'finish_breakdown_location',
  finish_destination = 'finish_destination',
}

export enum TowingRecordJobStatus {
  pending = 'pending',
  started = 'started',
  arrived_at_breakdown_location = 'arrived_at_breakdown_location',
  finished_at_breakdown_location = 'finished_at_breakdown_location',
  arrived_at_destination = 'arrived_at_destination',
  finished_at_destination = 'finished_at_destination',
  stopped = 'stopped',
  cancelled = 'cancelled',
  on_hold = 'on_hold',
}

export interface Coords {
  lat: Maybe<number>
  long: Maybe<number>
}

export enum CustomerType {
  company = 'company',
  person = 'person',
}

export enum ItemVatCategory {
  standard = 'standard',
  margin_tax_used_products = 'margin_tax_used_products',
  eu_tax_services = 'eu_tax_services',
  construction_tax_reverse_charge = 'construction_tax_reverse_charge',
  non_eu_tax = 'non_eu_tax',
  transports_and_cultural = 'transports_and_cultural',
  zero_tax_services = 'zero_tax_services',
  food_and_restaurants = 'food_and_restaurants',
  eu_tax_products = 'eu_tax_products',
}

export enum JobItemVatUnit {
  standard = '24%',
  margin_tax_used_products = '(KT)',
  eu_tax_services = '(pEU)',
  construction_tax_reverse_charge = '(RAK)',
  non_eu_tax = '(nEU)',
  transports_and_cultural = '10%',
  zero_tax_services = '0%',
  food_and_restaurants = '14%',
  eu_tax_products = '(tEU)',
}

export enum JobItemDiscountType {
  amount = 'amount',
  percentage = 'percentage',
}

export enum JobItemType {
  normal = 'normal',
  discount = 'discount',
}

export enum DeliveryMethod {
  mail = 'mail',
  email = 'email',
  e_invoice = 'e_invoice',
  no_delivery = 'no_delivery',
  sms = 'sms',
}

export enum SignatureType {
  written = 'written',
  online_acceptance = 'online_acceptance',
  online_acceptance_sms = 'online_acceptance_sms',
}

export enum UserRole {
  user = 'user',
  main_user = 'main_user',
  admin = 'admin',
  super_admin = 'super_admin',
  duty_officer = 'duty_officer',
  call_center = 'call_center',
  autofutur = 'autofutur',
  call_center2 = 'call_center2',
}

export interface User {
  userId: number
  username: string
  realName: string
  companyId: number
  role: UserRole
}

export interface DateTime {
  date: Maybe<Date> | Maybe<ShortDate>
  time: Maybe<Time>
}

export type NotificationMethod = 'email' | 'sms' | 'disabled' | 'default'

export enum TypeIdentifier {
  default = 'default',
  autoliitto = 'autoliitto',
  call_center = 'call_center',
  www = 'www',
  api = 'api',
  sos = 'sos',
  subcontract = 'subcontract',
  subcontract_client = 'subcontract_client',
  lahitapiola = 'lahitapiola',
}

export enum NotificationDelay {
  successNotificationDelay = 3000,
  infoNotificationDelay = 5000,
  errorNotificationDelay = 7000,
  googleAPIGeolocationErrorNotificationDelay = 7000,
  invalidParameterErrorNotificationDelay = 7000,
  invalidVisibilitySettingsNotificationDelay = 7000,
  statusChangeForbiddenNotificationDelay = 7000,
  genericErrorNotificationDelay = 0,
  unauthorizedNotificationDelay = 0,
  resourceNotFoundNotificationDelay = 0,
}
