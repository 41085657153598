import gql from 'graphql-tag'

import { sosServiceOrderFragment } from '../../../components/SosSubForm/types'
import { vehicleInfoFragment } from '../../../components/VehicleSearchModal/query'
import { ResourceNotFoundError, UnauthorizedError } from '../../errorTypes'
import { TowingOrder } from './types'

export const TOWING_ORDER_QUERY = gql`
  query TowingOrder($id: Int!) {
    towingOrder(id: $id) {
      ... on GetTowingOrderSuccess {
        towingOrder {
          id
          label
          stationId
          operatorId
          vehicleInfoCheckId
          type
          typeIdentifier
          status
          visibility
          vehicleDetails {
            registrationNumber
            vehicleClass
            makeAndModel
            owner
            holder
            insuranceCompany
          }
          jobDetails {
            serviceType
            towingDateType
            towingDate
            towingEndDate
            towingTime
            towingEndTime
            additionalInfo
            orderReference
            towingReason
            notes
            incidentCaseNo
          }
          automobileAndTouringClubFinland {
            jobId
            caseNumber
            serviceChannel
            billingInfo
            personCount
            vehicleMileage
            arcCodes {
              component {
                code
                label
              }
              description {
                code
                label
              }
              completion {
                code
                label
              }
            }
            repairShopId
            distanceTowed
            towingInsistedByCustomer
            delayedTowing
            delayedTowingReason
            jobTimestamps {
              estimatedTimeOfArrival {
                date
                time
              }
              actualTimeOfArrival {
                date
                time
              }
              estimatedTimeOfCompletion {
                date
                time
              }
              actualTimeOfCompletion {
                date
                time
              }
            }
            situationDescription
          }
          sosServiceOrder {
            ...SosServiceOrderFields
          }
          orderer {
            __typename
            id
            ... on PersonCustomer {
              firstName
              lastName
              ssn
              phone
              email
              address
            }
            ... on CompanyCustomer {
              companyName
              businessId
              contactPerson
              contactPersonEmail
              phone
              address
            }
          }
          from {
            address
            city
            description
            zipcode
            coords {
              lat
              long
            }
          }
          to {
            address
            city
            description
            zipcode
            coords {
              lat
              long
            }
          }
          vehicleInfoCheck {
            ...VehicleInfoFields
          }
        }
      }
    }
  }
  ${sosServiceOrderFragment.success}
  ${vehicleInfoFragment.success}
`

export interface TowingOrderQueryVariables {
  id: number
}

export interface TowingOrderResponse {
  towingOrder: TowingOrderOutput
}

export type TowingOrderOutput = TowingOrderSuccess | UnauthorizedError | ResourceNotFoundError

interface TowingOrderSuccess
  extends Readonly<{
    __typename: 'GetTowingOrderSuccess'
    towingOrder: TowingOrder
  }> {}
