import { ApolloLink, Observable, Operation } from 'apollo-link'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { getStoredLogin, hasStoredLogin, storeLogout } from './storedState'

import { ApolloCache } from 'apollo-cache'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { createConvertScalarsLink } from './convertScalarLink'
import gql from 'graphql-tag'
import { onError } from 'apollo-link-error'
import { withClientState } from 'apollo-link-state'

interface LinkState {
  authenticated: boolean
}

interface Subscription {
  closed: boolean
  unsubscribe(): void
}

const defaults: LinkState = {
  authenticated: hasStoredLogin(),
}

const resolvers = {
  Mutation: {
    login: (_1: never, _2: never, context: { cache: ApolloCache<any> }) => {
      context.cache.writeData<LinkState>({ data: { authenticated: true } })
      return null
    },
  },
}

export const LOGIN_CLIENTSTATE_MUTATION = gql`
  mutation loginClientState {
    login @client
  }
`

export const IS_AUTHENTICATED_QUERY = gql`
  query isAuthenticated {
    authenticated @client
  }
`

export const createGraphqlClient = () => {
  let introspectionQueryResultData

  try {
    introspectionQueryResultData = require('../generated/apollo/fragmentTypes.json')
  } catch (e) {
    throw new Error('Fragment types are not generated. You should run: yarn codegen to generate fragment types')
  }

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  const cache = new InMemoryCache({ fragmentMatcher })

  const convertScalarsLink = createConvertScalarsLink()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        // tslint:disable:no-console
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        // tslint:enable

        const code = extensions && extensions.code

        if (code === 'UNAUTHENTICATED') {
          storeLogout()
        }
        return null
      })
    }

    if (networkError) {
      // tslint:disable:no-console
      console.error(`[Network error]: ${networkError}`)
      // tslint:enable
    }
  })

  const request = async (operation: Operation) => {
    const token = getStoredLogin()

    if (token) {
      const { headers } = operation.getContext()

      operation.setContext({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
    }
  }

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: Subscription
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            if (forward) {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              })
            }
          })
          .catch(observer.error.bind(observer))

        return () => {
          if (handle) handle.unsubscribe()
        }
      })
  )

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      requestLink,
      withClientState({
        defaults,
        resolvers,
        cache,
      }),
      convertScalarsLink,
      new HttpLink({
        uri: '/.netlify/functions/graphql',
        credentials: 'same-origin',
      }),
    ]),
    cache,
  })

  return apolloClient
}
