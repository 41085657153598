import { useQuery } from '@apollo/react-hooks'
import React, { FunctionComponent } from 'react'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { TOWING_ORDER_QUERY, TowingOrderQueryVariables, TowingOrderResponse } from './towingOrderQuery'
import { ViewTowingOrderContent } from './ViewTowingOrderContent'

interface Props {
  id: number
}

export const ViewTowingOrderPage: FunctionComponent<Props> = ({ id }) => {
  const queryResult = useQuery<TowingOrderResponse, TowingOrderQueryVariables>(TOWING_ORDER_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const { refetch } = queryResult

  const TypedGenericResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingOrderResponse>

  return (
    <TypedGenericResultHandler queryResult={queryResult} loading unauthorized notFound>
      {data => {
        const refetchData = () => refetch()

        if (data && data.towingOrder.__typename === 'GetTowingOrderSuccess') {
          return <ViewTowingOrderContent refetchData={refetchData} towingOrder={data.towingOrder.towingOrder} />
        }
      }}
    </TypedGenericResultHandler>
  )
}
