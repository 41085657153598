import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrowDown } from '../svg/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../svg/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../svg/arrow-right.svg'
import { ReactComponent as ArrowUp } from '../svg/arrow-up.svg'
import { ReactComponent as CircleArrowRight } from '../svg/circle-arrow-right.svg'
import { ReactComponent as Clock } from '../svg/clock-shifts.svg'
import { ReactComponent as Close } from '../svg/close.svg'
import { ReactComponent as Done } from '../svg/done.svg'
import { ReactComponent as Edit } from '../svg/edit.svg'
import { ReactComponent as Error } from '../svg/error.svg'
import { ReactComponent as Garage } from '../svg/garage.svg'
import { ReactComponent as Info } from '../svg/info.svg'
import { ReactComponent as List } from '../svg/list.svg'
import { ReactComponent as Locate } from '../svg/locate.svg'
import { ReactComponent as MapMarker } from '../svg/location.svg'
import { ReactComponent as Lock } from '../svg/lock.svg'
import { ReactComponent as Search } from '../svg/search.svg'
import { ReactComponent as Settings } from '../svg/settings.svg'
import { ReactComponent as Success } from '../svg/success.svg'
import { ReactComponent as ThumbUp } from '../svg/thumb-up.svg'
import { ReactComponent as Trash } from '../svg/trash.svg'
import { ReactComponent as WorkWrench } from '../svg/work-wrench.svg'
import { ReactComponent as Write } from '../svg/write.svg'
import { ReactComponent as Minus } from '../svg/minus.svg'
import { ReactComponent as Plus } from '../svg/plus.svg'
import { ReactComponent as Messages } from '../svg/messages2.svg'

export type IconType =
  | 'map-marker'
  | 'arrow-left'
  | 'arrow-right'
  | 'thumbs-up'
  | 'checkmark'
  | 'edit'
  | 'done'
  | 'circle-arrow-right'
  | 'clock'
  | 'search'
  | 'settings'
  | 'work-wrench'
  | 'arrow-down'
  | 'arrow-up'
  | 'list'
  | 'close'
  | 'locate'
  | 'trash'
  | 'garage'
  | 'write'
  | 'success'
  | 'error'
  | 'info'
  | 'lock'
  | 'minus'
  | 'plus'
  | 'messages'

interface IconProps {
  icon: IconType
  color?: string
  size?: string
  marginBottom?: string
  marginRight?: string
  classname?: string
  inline?: boolean
  justify?: string
  align?: string
}

export const Icon: React.FunctionComponent<IconProps> = props => {
  const { icon, color, size, marginBottom, marginRight, inline, ...rest } = props

  return (
    <Container
      color={color}
      size={size}
      marginBottom={marginBottom}
      marginRight={marginRight}
      inline={inline}
      {...rest}
    >
      {icon === 'map-marker' && <MapMarker />}
      {icon === 'arrow-left' && <ArrowLeft />}
      {icon === 'arrow-right' && <ArrowRight />}
      {icon === 'arrow-down' && <ArrowDown />}
      {icon === 'edit' && <Edit />}
      {icon === 'done' && <Done />}
      {icon === 'thumbs-up' && <ThumbUp />}
      {icon === 'circle-arrow-right' && <CircleArrowRight />}
      {icon === 'clock' && <Clock />}
      {icon === 'search' && <Search />}
      {icon === 'settings' && <Settings />}
      {icon === 'work-wrench' && <WorkWrench />}
      {icon === 'arrow-up' && <ArrowUp />}
      {icon === 'list' && <List />}
      {icon === 'close' && <Close />}
      {icon === 'locate' && <Locate />}
      {icon === 'trash' && <Trash />}
      {icon === 'garage' && <Garage />}
      {icon === 'write' && <Write />}
      {icon === 'success' && <Success />}
      {icon === 'error' && <Error />}
      {icon === 'lock' && <Lock />}
      {icon === 'info' && <Info />}
      {icon === 'minus' && <Minus />}
      {icon === 'plus' && <Plus />}
      {icon === 'messages' && <Messages />}
    </Container>
  )
}

Icon.defaultProps = {
  size: 'big',
}

interface ContainerProps {
  color?: string
  size?: string
  marginBottom?: string
  marginRight?: string
  inline?: boolean
  justify?: string
  align?: string
}

const Container = styled.div<ContainerProps>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  ${props =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}

    ${props =>
      props.align &&
      css`
        align-self: ${props.align};
      `}

  svg {
    width: 4rem;
    height: 4rem;

    ${props =>
      props.size === 'x-small' &&
      css`
        width: 1.3rem;
        height: 1.3rem;
        margin-left: -0.1rem;
      `}
      ${props =>
        props.size === 'small' &&
        css`
          width: 1.6rem;
          height: 1.6rem;
        `}
      ${props =>
        props.marginBottom &&
        css`
          margin-bottom: ${props.marginBottom};
        `}
    ${props =>
      props.size === 'medium' &&
      css`
        width: 2.5rem;
        height: 2.5rem;
      `}

      ${props =>
        props.size === 'large' &&
        css`
          width: 6rem;
          height: 6rem;
          @media (max-width: ${props => props.theme.screenSize.mobile}) {
            width: 3.6rem;
            height: 3.6rem;
          }
        `}
        ${props =>
          props.size === 'offline' &&
          css`
            width: 3rem;
            height: 3rem;
          `}
          ${props =>
            props.size === 'shift-heading' &&
            css`
              width: 2rem;
              height: 2rem;
            `}
  }
  svg * {
    fill: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
  }
`
