import React, { RefObject, memo, useContext, useState, useEffect } from 'react'

import { towingServiceTypeOptions } from '../../../common/options'
import { FormatMessageType, TowingOrderType, TypeIdentifier } from '../../../common/types'
import { ALOrderSubForm } from '../../../components/ALOrderSubForm'
import { Button, HalfButton } from '../../../components/Button'
import { messages as buttonMessages } from '../../../components/Button/messages'
import {
  LahitapiolaDefaultOrderDetailsForm,
  LahitapiolaSosOrderDetailsForm,
} from '../../../components/LahitapiolaSubForm'
import {
  FieldButtonRow,
  FormPadder,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
  FormSubGroupsList,
  Section,
  SectionColumn,
  SimpleRow,
} from '../../../components/layout'
import { TextInput } from '../../../components/layout/form/input'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { Modal } from '../../../components/Modal'
import { useHistoryPush } from '../../../components/router'
import { SearchVehicleInfo } from '../../../components/SearchVehicleInfo'
import { SosServiceOrderDetailsForm } from '../../../components/SosSubForm/TowingOrderForm/EditTowingOrder'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import { persistentDelete } from '../../../util/localStorage/localStorage'
import { translateInputOptions } from '../../../util/translateInputOptions'
import { CustomerFormFields } from '../../Customer/CustomerFormFields'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { EditCustomer } from '../../Customer/EditCustomer'
import { CustomerFormValues } from '../../Customer/types'
import { calculateStationCount } from '../../Parameters/station'
import { StateStore } from '../../StoreProvider'
import { HeadingColumn, IconColumn } from '../../TowingRecordPage/EditTowingRecord/EditTowingRecordPage'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { TowingOrderRoutesForm } from '../AddTowingOrder/TowingOrderRoutesForm'
import { TowingOrderSaveButtons } from '../components/SaveButtons'
import { messages } from '../messages'
import { DateSectionColumn, TowingOrderDetailsSubFormContents } from './TowingOrderDetailsSubFormContents'
import { TowingOrderSettingsForm } from './TowingOrderSettingsForm'
import { EditTowingOrder } from './types'

const convertModalMessage = `Täydennä puuttuvat tiedot. Kun kaikki tiedot on täytetty ja painat tallenna, niin tilaus muunnetaan SOS tilaukseksi.`

interface FormContentsProps {
  formatMessage: FormatMessageType
  onCancelVehicleDetails: () => void
  onHandleVehicleResults: (res: VehicleInfo) => void
  onSetOrderer: (customer: CustomerFormValues) => void
  closeForm: () => void
  showTowingDateSpan: boolean
  orderer: CustomerFormValues
  ssn: string
  registrationNumber: string
  setFieldValue: (field: string, value: any) => void
  operatorDisabled: boolean
  setStoreFormValues: (storeValues: boolean) => void
  typeIdentifier: TypeIdentifier
  isSos?: boolean
  onSubmit: any
  getValue: any
  // onEditTime?: (delayedTowing: boolean) => void SIIRRETTY SISEMPÄÄN KOMPONENTTIIN
  convertToSosOrder: () => void
  scrollToSos?: boolean
  businessId: string
  towingOrder: EditTowingOrder
  ownersHolders: OwnersHoldersTrafi[]
  setOrdererFromTrafi: (customer: CustomerFormValues) => void
  // onChangeVisibility: (value: string | number | undefined) => void
  scrollTo: string | undefined
}

export const TowingOrderFormContentsMemo: React.FunctionComponent<FormContentsProps> = ({
  formatMessage,
  onCancelVehicleDetails,
  onHandleVehicleResults,
  onSetOrderer,
  showTowingDateSpan,
  orderer,
  ssn,
  registrationNumber,
  setFieldValue,
  operatorDisabled,
  setStoreFormValues,
  onSubmit,
  typeIdentifier,
  isSos = false,
  getValue,
  convertToSosOrder,
  scrollToSos,
  businessId,
  towingOrder,
  ownersHolders,
  setOrdererFromTrafi,
  scrollTo,
}) => {
  const [showCustomerEditForm, setShowCustomerEditForm] = useState<boolean>(false)
  const [showConvertToSosModal, setShowConvertToSosModal] = useState<boolean>(false)

  const { state } = useContext(StateStore)
  const historyPush = useHistoryPush()
  const atjRef: RefObject<any> = React.createRef()

  useEffect(() => {
    if (scrollTo === 'atj') {
      atjRef.current.scrollIntoView()
    }
  })

  if (!state.settings) {
    return null
  }

  const operatorId = getValue('operatorId')
  const userId = state.currentUser ? state.currentUser.userId : null
  const userRole = state.currentUser ? state.currentUser.role : null
  const orderType = isSos ? TowingOrderType.sos : TowingOrderType.default
  const stationRequired = state.settings ? calculateStationCount(state.settings.stations) > 0 : false
  const {
    modules: { sos: canConvertToSos },
    notifications,
  } = state.settings

  const sosRef: RefObject<any> = React.createRef()

  return (
    <>
      {showCustomerEditForm && (
        <EditCustomer
          orderer={orderer}
          onClose={() => setShowCustomerEditForm(false)}
          onUpdate={updatedOrderer => {
            onSetOrderer(updatedOrderer)
            setShowCustomerEditForm(false)
          }}
        />
      )}
      {showConvertToSosModal && (
        <Modal
          label="Muunna SOS tilaukseksi"
          message={convertModalMessage}
          close={() => setShowConvertToSosModal(false)}
          onConfirm={() => convertToSosOrder()}
          confirmLabel="Muunna SOS tilaukseksi"
          closeLabel="Peruuta"
        />
      )}

      <Form>
        {/* {showConvertToSosModal && (
          <ConvertToSosOrderModal onConvert={convertToSosOrder} onClose={() => setShowConvertToSosModal(false)} />
        )} */}
        <SimpleRow margin="0.5rem 1rem 0.5rem 0.5rem" mobileMargin="0.5rem 0.5rem 0.5rem 0" justify="space-between">
          <IconColumn
            direction="back"
            label="Takaisin"
            onClick={() => {
              setStoreFormValues(false)
              persistentDelete('towingOrder')
              historyPush(`/towing-jobs`)
            }}
          />
          {canConvertToSos &&
            typeIdentifier !== TypeIdentifier.autoliitto &&
            typeIdentifier !== TypeIdentifier.lahitapiola &&
            !isSos && (
              <HeadingColumn>
                <Button category="save" label={'Muunna SOS työksi'} onClick={() => setShowConvertToSosModal(true)} />
              </HeadingColumn>
            )}
        </SimpleRow>
        <FormSubGroupsList>
          <TowingOrderSettingsForm
            operatorDisabled={operatorDisabled}
            isAl={typeIdentifier === TypeIdentifier.autoliitto}
            stationRequired={stationRequired}
            notificationSettings={notifications}
            isSos={isSos}
            userRole={userRole}
          />
          <FormSubGroupWrapper>
            <FormSubGroupHeader>
              <FormSubGroupHeading>
                <FormSubGroupHeading>{formatMessage(messages.vehicleDetails)}</FormSubGroupHeading>
              </FormSubGroupHeading>
            </FormSubGroupHeader>
            <FormSubGroup ref={atjRef}>
              <Section>
                <SectionColumn>
                  <FieldButtonRow sameBorderRadius>
                    <TextInput
                      label={formatMessage(messages.registrationNumber)}
                      name="vehicleDetails.registrationNumber"
                      required
                      toUpperCase={true}
                    />
                    <SearchVehicleInfo
                      buttonText="Vie tilaukseen"
                      handleResults={onHandleVehicleResults}
                      searchString={registrationNumber}
                      vehicleInfoCheckId={getValue('vehicleInfoCheckId')}
                    />
                  </FieldButtonRow>
                </SectionColumn>

                <SectionColumn>
                  <TextInput label={formatMessage(messages.vehicleClass)} name="vehicleDetails.vehicleClass" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.makeAndModel)} name="vehicleDetails.makeAndModel" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.insuranceCompany)} name="vehicleDetails.insuranceCompany" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.owner)} name="vehicleDetails.owner" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.holder)} name="vehicleDetails.holder" />
                </SectionColumn>
              </Section>
              <Section>
                <SectionColumn hideOnMobile />
                <SectionColumn hideOnMobile hideOnTablet />
                <DateSectionColumn reverse>
                  <HalfButton
                    category="cancelSecondary"
                    label={formatMessage(buttonMessages.clear)}
                    onClick={() => onCancelVehicleDetails()}
                  />
                </DateSectionColumn>
              </Section>

              {/* <ButtonRow>
                <ButtonColumn>
                  <Button
                    category="cancelSecondary"
                    label={formatMessage(buttonMessages.clear)}
                    onClick={() => onCancelVehicleDetails()}
                  />
                </ButtonColumn>
              </ButtonRow> */}
            </FormSubGroup>
          </FormSubGroupWrapper>
          <CustomerFormFields
            editCustomer={() => setShowCustomerEditForm(true)}
            orderer={orderer}
            clearOrderer={() => onSetOrderer(initialOrderer)}
            onUpdate={updatedOrderer => onSetOrderer(updatedOrderer)}
            ssn={ssn}
            setFieldValue={setFieldValue}
            isSos={isSos}
            businessId={businessId}
            ownersHolders={ownersHolders}
            setOrdererFromTrafi={setOrdererFromTrafi}
          />
          {typeIdentifier === TypeIdentifier.autoliitto && (
            <ALOrderSubForm
              serviceTypeOptions={translateInputOptions(towingServiceTypeOptions, formatMessage)}
              setFieldValue={setFieldValue}
              getOriginalValue={getValue}
              towingOrder={towingOrder}
            />
          )}
          {isSos && typeIdentifier === TypeIdentifier.sos && (
            <SosServiceOrderDetailsForm
              getValue={getValue}
              setValue={setFieldValue}
              sosRef={sosRef}
              scrollToThis={scrollToSos}
            />
          )}
          {isSos && typeIdentifier === TypeIdentifier.lahitapiola && (
            <LahitapiolaSosOrderDetailsForm getValue={getValue} setValue={setFieldValue} />
          )}
          {typeIdentifier === TypeIdentifier.lahitapiola && !isSos && <LahitapiolaDefaultOrderDetailsForm />}
          {!isSos && typeIdentifier !== TypeIdentifier.autoliitto && typeIdentifier !== TypeIdentifier.lahitapiola && (
            <TowingOrderDetailsSubFormContents formatMessage={formatMessage} showTowingDateSpan={showTowingDateSpan} />
          )}
          <TowingOrderRoutesForm
            setValue={setFieldValue}
            getValue={getValue}
            orderType={orderType}
            deliverableName="sosServiceOrder.deliverable"
          />
        </FormSubGroupsList>
        <FormPadder>
          <TowingOrderSaveButtons userId={userId} userRole={userRole} operatorId={operatorId} handleSubmit={onSubmit} />
        </FormPadder>
        <ScrollToError />
      </Form>
    </>
  )
}

export const TowingOrderFormContents = memo(TowingOrderFormContentsMemo)
