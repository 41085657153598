import React from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/Icon'
import { useHistoryPush } from '../router'

type DirectionType = 'back' | 'forward'

interface NavigateProps {
  label?: string
  direction: DirectionType
  onClick?: () => void
}

export const Navigate: React.FunctionComponent<NavigateProps> = ({ direction, label, onClick }) => {
  return (
    <NavigateToWrapper onClick={onClick}>
      {direction === 'back' && <Icon size="medium" icon={'arrow-left'} />}
      {label && <LabelWrapper>{label}</LabelWrapper>}
      {direction === 'forward' && <Icon size="medium" icon={'arrow-right'} />}
    </NavigateToWrapper>
  )
}

interface NavigateToProps {
  slug: string
  label?: string
  direction: DirectionType
  onNavigate?: () => void
}

export const NavigateTo: React.FunctionComponent<NavigateToProps> = ({ direction, label, onNavigate, slug }) => {
  const historyPush = useHistoryPush()
  return (
    <Navigate
      direction={direction}
      label={label}
      onClick={() => {
        if (onNavigate) onNavigate()
        historyPush(slug)
      }}
    />
  )
}

const NavigateToWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.5rem;
  z-index: 2;
`

const LabelWrapper = styled.div`
  padding: 0;
  font-weight: 700;
`
