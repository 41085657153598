import axios from 'axios'

import {
  getServiceWorkerEndpoint,
  removeServiceWorkerEndpoint,
  storeBackendNotifications,
  storeLogin,
  storeLogout,
} from '../graphql-client/storedState'
import * as serviceWorker from '../serviceWorker'
import { BackendNotification } from './notifications/types'
import { sendMessageToWebView } from './react-native'
import { WebMessageType } from './react-native/types'

interface LoginResponse {
  accessToken: string
  notifications?: BackendNotification[]
}

export const login = async (username: string, password: string, loginClientState: () => void) => {
  const response = await axios.request<{ data: LoginResponse }>({
    method: 'POST',
    withCredentials: true,
    url: `${process.env.REACT_APP_REST_API_URL}/auth/session/login`,
    data: {
      username,
      password,
      source: 'innohinaus-app',
    },
  })

  const token = response.data.data.accessToken
  const notifications = response.data.data.notifications

  if (notifications) {
    storeBackendNotifications(notifications)
  }

  loginClientState()
  storeLogin(token)
  serviceWorker.registerNotificationWorker()
}

export const checkToken = async (loginClientState: () => void) => {
  const response = await axios.request<{ data: LoginResponse }>({
    method: 'GET',
    withCredentials: true,
    url: `${process.env.REACT_APP_REST_API_URL}/auth/session/token`,
  })

  const token = response.data.data.accessToken

  if (token === null) {
    return
  }

  const notifications = response.data.data.notifications
  if (notifications) {
    storeBackendNotifications(notifications)
  }

  loginClientState()
  storeLogin(token)
  serviceWorker.registerNotificationWorker()
}

export const logout = async () => {
  await axios.request<{ data: LoginResponse }>({
    method: 'POST',
    withCredentials: true,
    url: `${process.env.REACT_APP_REST_API_URL}/auth/session/logout`,
    data: {
      pushSubscriptionEndpoint: getServiceWorkerEndpoint(),
    },
  })

  removeServiceWorkerEndpoint()
  storeLogout()
  serviceWorker.unregister()
  sendMessageToWebView({ type: WebMessageType.LOGOUT })
}
