import { FieldProps, getIn, FormikProps } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Error, FieldContainer, FormikField as Field, Label } from '../../style'

interface Props extends FieldProps {
  id: string
  label?: string
  name: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  onValueChange?: (_: any, form: FormikProps<any>) => void
  onBlur?: (form: FormikProps<any>) => void
  type?: string
  toUpperCase?: boolean
}

interface CustomCityInputProps extends Props {
  hasCoordinates?: boolean
}

export const TextField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  placeholder,
  disabled = false,
  required,
  onValueChange,
  onBlur,
  type = 'text',
  toUpperCase = false,
  ...rest
}) => {
  const [value, setValue] = useState<string | ''>(field.value)
  useEffect(() => {
    const newValue = field.value || ''

    setValue(newValue)
  }, [field.value])

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      let trimmedValue = targetValue.trim()

      if (toUpperCase) trimmedValue = trimmedValue.toUpperCase()

      form.setFieldValue(field.name, trimmedValue)
      form.setFieldTouched(field.name, true)
      if (onValueChange) {
        onValueChange(event, form)
      }
    },
    [field, form, onValueChange, toUpperCase]
  )

  const handleBlur = useCallback(() => {
    if (field.value && onBlur) {
      onBlur(form)
    }
  }, [field.value, onBlur, form])

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)
  const isCityInputAndHasCoordinates = checkForCoordinates({ form, field })
  return (
    <FieldContainer {...rest}>
      {label && <Label required={required}>{label}</Label>}
      <StyledField
        id={id}
        type={type}
        name={field.name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete="off"
        hasCoordinates={isCityInputAndHasCoordinates}
      />
      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}

const checkForCoordinates = (formik: FieldProps) => {
  const { field, form } = formik
  if (!field.name.includes('city')) return false
  const coords = getIn(form.values, field.name.replace('city', 'coords'))
  if (coords && coords.lat && coords.long) return true
  return false
}

const StyledField = styled(Field)<CustomCityInputProps>`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: ${props => props.theme.inputFieldHeight};
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.grey200
      : props.hasCoordinates
      ? props.theme.colors.green100
      : props.theme.colors.white};
  ${props =>
    !props.disabled &&
    props.hasCoordinates &&
    css`
      border: 2px solid ${props.theme.colors.green600};
    `}
`
// 2px solid #43a047
