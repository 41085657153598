import { FieldProps, getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Error, FormikField as Field } from '../../style'

interface Props {
  id: string
  label?: string
  name: string
  disabled?: boolean
  required?: boolean
}

export const CheckboxField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  disabled = false,
}) => {
  const [value, setValue] = useState<boolean>(field.value)
  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const handleChange = useCallback(
    event => {
      const checked = event.target.checked
      setValue(checked)
      form.setFieldValue(field.name, checked)
      form.setFieldTouched(field.name, true)
    },
    [field, form]
  )

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <CheckboxContainer>
      <Field
        id={id}
        component={Checkbox}
        type="checkbox"
        name={field.name}
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />
      {label && (
        <CheckboxLabel
          onClick={() => {
            handleChange({ target: { checked: !value } })
          }}
        >
          {label}
        </CheckboxLabel>
      )}
      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </CheckboxContainer>
  )
}

const Checkbox = styled.input`
  appearance: none;
  margin: 0;
  padding: 0;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.radius.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  &:checked:after {
    content: '\\2714';
    font-size: 1.25rem;
    color: ${props => props.theme.colors.black};
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const CheckboxLabel = styled.div`
  margin: 0 0 0 1rem;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
`
