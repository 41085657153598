import React from 'react'
import Loader from 'react-loader-spinner'
import styled, { css } from 'styled-components'
import { Icon } from '../icons/Icon'
import { useClientSize } from '../../util/useClientSize/useClientSize'

interface ButtonStyle {
  color: ColorType
  backgroundColor: ColorType
  icon?: any
  outline: boolean
  iconSize?: 'small' | 'medium' | 'large'
  borderColor?: ColorType
}

type ColorType =
  | 'red800'
  | 'yellow800'
  | 'orange800'
  | 'black'
  | 'white'
  | 'green800'
  | 'blue800'
  | 'grey300'
  | 'yellow400'
  | 'yellow800'
  | 'grey500'

export type ButtonCategory =
  | 'cancel'
  | 'cancelSecondary'
  | 'clear'
  | 'edit'
  | 'new'
  | 'save'
  | 'search'
  | 'regular'
  | 'logout'
  | 'off'
  | 'on'
  | 'locate'
  | 'danger'
  | 'back'
  | 'info'
  | 'yellow'
  | 'editSecondary'
  | 'clock'

export const buttonStyle: Record<ButtonCategory, ButtonStyle> = {
  cancel: { color: 'white', backgroundColor: 'red800', icon: 'times', outline: false },
  cancelSecondary: { color: 'white', backgroundColor: 'red800', outline: false },
  clear: { color: 'white', backgroundColor: 'orange800', icon: 'times', outline: false },
  edit: { color: 'yellow800', backgroundColor: 'white', icon: 'edit', outline: true },
  editSecondary: { color: 'yellow800', backgroundColor: 'white', outline: true },
  new: { color: 'black', backgroundColor: 'white', outline: true },
  save: { color: 'white', backgroundColor: 'green800', outline: false },
  danger: { color: 'white', backgroundColor: 'orange800', outline: false },
  search: { color: 'white', backgroundColor: 'blue800', icon: 'search', outline: false },
  regular: { color: 'white', backgroundColor: 'black', outline: false },
  logout: { color: 'white', backgroundColor: 'black', outline: false },
  on: { color: 'white', backgroundColor: 'blue800', outline: false },
  off: { color: 'black', backgroundColor: 'grey300', outline: false },
  locate: { color: 'white', backgroundColor: 'blue800', outline: false, icon: 'map-marker' },
  back: { color: 'black', backgroundColor: 'white', outline: false, icon: 'arrow-left', iconSize: 'medium' },
  info: { color: 'blue800', backgroundColor: 'white', outline: true, iconSize: 'large' },
  yellow: { color: 'black', backgroundColor: 'yellow400', outline: true, borderColor: 'yellow800' },
  clock: {
    color: 'white',
    backgroundColor: 'blue800',
    icon: 'clock',
    // secondaryColor: 'blue700',
    outline: false,
  },
}

export interface ButtonProps {
  category: keyof typeof buttonStyle
  label?: string
  onClick?: () => void
  submit?: boolean
  disabled?: boolean
  size?: 'xl' | 'l' | 's' | 'm' | 'ml'
  children?: React.ReactNode
  className?: string
  loading?: boolean
  iconSize?: string
  alignLeft?: boolean
  alignRight?: boolean
  name?: string
  maxWidth?: string
  useStyle?: boolean
  fontSize?: string
  fontWeight?: string
  mobileWidth?: string
  padding?: string
  mobileLabel?: string
  selectButton?: boolean
  secondaryCategory?: keyof typeof buttonStyle
  selected?: boolean
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  alignLeft,
  alignRight,
  children,
  size,
  category,
  disabled,
  label,
  onClick,
  submit,
  className,
  loading = false,
  iconSize = 'small',
  name,
  maxWidth,
  useStyle = true,
  fontSize,
  fontWeight,
  mobileWidth,
  padding,
  mobileLabel,
  selectButton = false,
  secondaryCategory = null,
  selected = false
}) => {
  //const [buttonCategory, setButtonCategory] = useState<ButtonCategory>(category)
  const { backgroundColor, color, icon, outline, borderColor } = selectButton && secondaryCategory && selected ? buttonStyle[secondaryCategory] : buttonStyle[category]
  const { width } = useClientSize()
  const isMobile = width ? width <= 600 : false
  const useMobileLabel = isMobile && mobileLabel
  
  //selectButton && secondaryCategory && selected ? setButtonCategory(secondaryCategory) : setButtonCategory(category)

  return (
    <StyledButton
      name={name}
      className={className}
      type={submit ? 'submit' : 'button'}
      color={color}
      backgroundColor={backgroundColor}
      onClick={!disabled ? onClick : undefined}
      outline={outline}
      disabled={disabled || loading}
      size={size}
      alignLeft={alignLeft}
      alignRight={alignRight}
      category={category}
      maxWidth={maxWidth}
      useStyle={useStyle}
      fontSize={fontSize}
      fontWeight={fontWeight}
      borderColor={borderColor}
      mobileWidth={mobileWidth}
      padding={padding}
    >
      {loading && <Loader type="Rings" color={color} width={25} height={25} />}
      {icon && !loading && <Icon size={iconSize} icon={icon} color={color} />}
      {(icon || loading) && label && <Spacing />}
      {!useMobileLabel && label && <span>{label}</span>}
      {useMobileLabel && <span>{mobileLabel}</span>}
      {children}
    </StyledButton>
  )
}

interface StyledButtonProps {
  name?: string
  className?: string
  outline: boolean
  backgroundColor: string
  color: string
  size?: string
  alignLeft?: boolean
  alignRight?: boolean
  disabled?: boolean
  category: string
  maxWidth?: string
  useStyle?: boolean
  fontSize?: string
  fontWeight?: string
  borderColor?: string
  mobileWidth?: string
  padding?: string
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding: ${props => (props.padding ? props.padding : '0 1.5rem')};
  font-size: 1rem;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : props.theme.fontWeight.bold)};
  text-align: center;
  white-space: nowrap;
  height: 40px;
  outline: none;
  max-width: calc(320px - 1rem);
  ${props =>
    props.alignLeft &&
    css`
      justify-content: flex-start;
      padding: 0;
    `}
  ${props =>
    props.alignRight &&
    css`
      justify-content: flex-end;
      padding: 0;
    `}
  ${props =>
    props.size &&
    props.size === 's' &&
    css`
      height: ${props.theme.inputFieldHeight};
    `}
    ${props =>
      props.size &&
      props.size === 'm' &&
      css`
        height: 60px;
      `}
  ${props =>
    props.size &&
    (props.size === 'l' || props.size === 'ml') &&
    css`
      height: 72px;
      font-size: 1.5rem;
    `}
    ${props =>
      props.size &&
      props.size === 'xl' &&
      css`
        height: 6rem;
        font-size: 2.5rem;
        max-width: 100%;
      `}
      ${props =>
        props.fontSize &&
        css`
          font-size: ${props.fontSize};
        `}
  width: 100%;
  color: ${props => props.theme.colors[props.color]};
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  border-radius: ${props => props.theme.radius.large};
  border: 0;
  ${props =>
    props.outline &&
    css`
      border: 1px solid;
    `};
    ${props =>
      props.borderColor &&
      css`
        border-color: ${props.theme.colors[props.borderColor]};
      `}
    ${props =>
      props.disabled &&
      css`
        color: ${props.theme.colors.grey500};
        background-color: ${props.theme.colors.grey300};
        font-weight: ${props.theme.fontWeight.normal};
        font-style: italic;
        cursor: default;
      `}
      ${props =>
        props.maxWidth &&
        css`
          max-width: ${props.maxWidth};
        `}
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-size: 1rem;
    ${props =>
      props.size &&
      props.size === 'ml' &&
      css`
        max-width: 100%;
        max-height: 45px;
      `}
    ${props =>
      props.size &&
      props.size === 'l' &&
      css`
        max-width: 100%;
        min-height: 40px;
      `}
    ${props =>
      props.size &&
      props.size === 'xl' &&
      css`
        height: 3rem;
        font-size: 1.25rem;
        max-width: 100%;
      `}
      ${props =>
        props.size &&
        props.size === 'm' &&
        css`
          height: 50px;
        `}
      ${props =>
        props.mobileWidth &&
        css`
          max-width: ${props.mobileWidth};
          width: ${props.mobileWidth};
        `}
  }

  /* for onlick animation: */

  ${props =>
    props.category !== 'locate' &&
    props.useStyle &&
    css`
      transition-duration: 0.2s;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        background: ${props.theme.colors[props.color]};
        mix-blend-mode: difference;
        display: block;
        position: absolute;
        margin-left: -20px !important;
        margin-top: -10%;
        opacity: 0;
        border-radius: 80px;
        transition: all 0.8s;
      }
      &:active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s;
      }
    `}
`

const Spacing = styled.div`
  width: 0.5rem;
`

export const HalfButton = styled(Button)`
  width: calc(50% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }
`
