import { useQuery } from '@apollo/react-hooks'
import React, { useContext, useEffect } from 'react'
import { GET_CURRENT_USER } from './queries'
import { DispatchStore } from '../StoreProvider'
import { User } from '../../common/types'
import { setCurrentUser } from '../StoreProvider/actions'

interface UserResponse {
  user: {
    __typename: string
    user: User
  }
}

export const GetCurrentUser: React.FunctionComponent = () => {
  const { dispatch } = useContext(DispatchStore)

  const queryResponse = useQuery<UserResponse>(GET_CURRENT_USER)

  useEffect(() => {

    if (queryResponse && queryResponse.data && queryResponse.data.user) {
      dispatch(setCurrentUser(queryResponse.data.user.user))
    }
  }, [dispatch, queryResponse])

  return null
}
