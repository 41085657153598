import ApolloClient from 'apollo-client'
import { DocumentNode } from 'graphql'
import React, { useContext, useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import { Maybe, Time, TowingOrderType, TypeIdentifier, User, UserRole } from '../../../common/types'
import { ALTimeChangeModal } from '../../../components/ALOrderSubForm/ALTimeChangeModal'
import { BigButtonContent, BigButtonSection } from '../../../components/layout'
import { Loading } from '../../../components/responses'
import { Styling } from '../../../components/Styling'
import { maybeStartTrackingRecord } from '../../../components/TrackedJobs/util'
import { DispatchStore, StateStore } from '../../StoreProvider'
import { setOpenJobsCount } from '../../StoreProvider/actions'
import { TowingRecord } from '../../TowingRecordPage/ViewTowingRecord/types'
import { RejectType } from '../components/RejectOrder/types'
import { StatusType } from '../types'
import { errorMessage } from './errorMessage'
import {
  ACCEPT_AND_ASSIGN_TOWING_ORDER,
  ACCEPT_SOS_SERVICE_ORDER,
  ACCEPT_TOWING_ORDER,
  APPROVE_TOWING_ORDER_ASSIGNMENT,
  CANCEL_TOWING_ORDER,
  CREATE_TOWING_RECORD_FROM_ORDER,
  REJECT_TOWING_ORDER,
} from './queries'
import { StatusChangeButton } from './StatusChangeButton'
import { TowingRecordFromOrderOutputResponse } from './TowingRecordFromOrderMutation'
import { TowingOrder } from './types'

interface ButtonsProps {
  status: StatusType
  type: TowingOrderType
  towingOrderId: number
  handleSuccess: (msg: string) => void
  handleError: (msg: string) => void
  openRejectPage: (type: RejectType) => void
  towingOrder: TowingOrder
}

export const StatusChangeButtons: React.FunctionComponent<ButtonsProps> = ({
  handleError,
  handleSuccess,
  status,
  towingOrderId,
  type,
  openRejectPage,
  towingOrder,
}) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  const [createdTowingRecordId, setCreatedTowingRecordId] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { dispatch } = useContext(DispatchStore)
  const {
    state: { currentUser },
  } = useContext(StateStore)

  const isOwn = currentUser && currentUser.userId === towingOrder.operatorId
  const isSos = type === TowingOrderType.sos
  const isMainUser = currentUser && (currentUser.role === UserRole.duty_officer || currentUser.role === UserRole.main_user)
  const isOpenAndMainUser =  status === 'open' && isMainUser

  const approveAndAssign = status === 'pending_acceptance'
  const approveAndLeaveOpen = status === 'pending_acceptance'
  const userAccept = isOwn && status === 'assigned'
  const anyAccept = status === 'open'
  const userReject = isOwn && status === 'assigned'
  const cancel = status === 'pending_acceptance'
  const beginWork = isOwn && status === 'accepted'
  const towingrecordCreated = status === 'towing_record_created'
  const cancelSos = isSos && (status === 'accepted' || status === 'assigned' || isOpenAndMainUser)

  const promptTime = 20

  const setTRid = (data: any) => {
    const res = data as TowingRecordFromOrderOutputResponse
    if (res.towingRecordFromOrder && res.towingRecordFromOrder.__typename === 'TowingRecordFromOrderSuccess') {
      setCreatedTowingRecordId(res.towingRecordFromOrder.towingRecord.id)
    }
  }

  const maybeStartGeolocationTracking = (data: any) => {
    const res = data as TowingRecordFromOrderOutputResponse
    if (res.towingRecordFromOrder && res.towingRecordFromOrder.__typename === 'TowingRecordFromOrderSuccess') {
      const towingRecord = res.towingRecordFromOrder.towingRecord
      maybeStartTrackingRecord(towingRecord as TowingRecord)
    }
  }

  const changeStatus = async (
    client: ApolloClient<any>,
    updateMutation: DocumentNode,
    variables: object,
    resultIsTowingRecord?: boolean
  ) => {
    try {
      setLoading(true)
      const response = await client.mutate({
        variables,
        mutation: updateMutation,
      })

      const errorMsg = errorMessage(response.data)
      if (errorMsg) {
        handleError(errorMsg)
        setLoading(false)
      } else {
        dispatch(setOpenJobsCount(null))
        if (resultIsTowingRecord) {
          setTRid(response.data)
          maybeStartGeolocationTracking(response.data)
        }
        handleSuccess('Tila päivitetty onnistuneesti')
        setLoading(false)
      }
    } catch (error) {
      handleError((error as any).message)
      setLoading(false)
    }
  }

  const getMinutesSinceOrdered = (date: Date, time: Time) => {
    if(!time){
      return 1;
    }
    const hourMin = time.split(':')
    const currentTime = Date.now()
    date.setHours(parseInt(hourMin[0]), parseInt(hourMin[1]))
    return Math.abs(currentTime - date.getTime()) / 60000
  }

  const promptBeforeSave = () => {
    if (towingOrder.typeIdentifier !== TypeIdentifier.autoliitto) {
      return false
    }
    
    // This is a case when AL-order has been copied (type autoliitto, but no autoliitto_order exists)
    if (towingOrder.automobileAndTouringClubFinland === null) {
      return false
    }

    if (towingOrder.automobileAndTouringClubFinland && towingOrder.automobileAndTouringClubFinland.delayedTowingReason){
      return false
    }

    if (getMinutesSinceOrdered(towingOrder.jobDetails.towingDate, towingOrder.jobDetails.towingTime) >= promptTime) {
      return true
    }

    return false
  }

  const url = `/towing-record/${createdTowingRecordId}/start`

  return (
    <ApolloConsumer>
      {client => (
        <BigButtonSection>
          <Loading loading={loading} />
          {approveAndAssign && (
            <StatusChangeButton
              category="save"
              changeStatus={() => {
                const mutation =
                  type === TowingOrderType.sos ? ACCEPT_SOS_SERVICE_ORDER : ACCEPT_AND_ASSIGN_TOWING_ORDER
                const mutationVariables =
                  type === TowingOrderType.sos
                    ? toSosMutationVariables(towingOrderId, currentUser)
                    : { id: towingOrderId }
                changeStatus(client, mutation, mutationVariables)
              }}
            >
              <BigButtonContent>
                <Styling fontSize="m">Hyväksy ja</Styling>
                <Styling fontSize="l">kuittaa itselle</Styling>
              </BigButtonContent>
            </StatusChangeButton>
          )}
          {approveAndLeaveOpen && (
            <StatusChangeButton
              category="save"
              changeStatus={() => changeStatus(client, ACCEPT_TOWING_ORDER, { id: towingOrderId })}
            >
              <BigButtonContent>
                <Styling fontSize="m">Hyväksy ja</Styling>
                <Styling fontSize="l">jätä avoimeksi</Styling>
              </BigButtonContent>
            </StatusChangeButton>
          )}
          {(userAccept || anyAccept) && (
            <StatusChangeButton
              category="save"
              label={'Kuittaa itselle'}
              changeStatus={() => {
                const mutation =
                  type === TowingOrderType.sos ? ACCEPT_SOS_SERVICE_ORDER : APPROVE_TOWING_ORDER_ASSIGNMENT
                const mutationVariables =
                  type === TowingOrderType.sos
                    ? toSosMutationVariables(towingOrderId, currentUser)
                    : { id: towingOrderId }
                changeStatus(client, mutation, mutationVariables)
              }}
            />
          )}
          {userReject && (
            <StatusChangeButton
              category="cancelSecondary"
              label={'Hylkää'}
              changeStatus={() => {
                // if (type === TowingOrderType.sos) {
                //   openRejectPage('reject')
                //   return
                // }
                changeStatus(client, REJECT_TOWING_ORDER, { input: { id: towingOrderId } })
              }}
            />
          )}
          {cancel && (
            <StatusChangeButton
              category="cancelSecondary"
              label={'Hylkää'}
              changeStatus={() => {
                if (type === TowingOrderType.sos) {
                  openRejectPage('cancel-sos')
                  return
                }
                changeStatus(client, CANCEL_TOWING_ORDER, { input: { id: towingOrderId } })
              }}
            />
          )}
          {beginWork && !promptBeforeSave() && (
            <StatusChangeButton
              category="save"
              label={'Aloita työ'}
              changeStatus={() => changeStatus(client, CREATE_TOWING_RECORD_FROM_ORDER, { towingOrderId }, true)}
            />
          )}
          {beginWork && promptBeforeSave() && (
            <StatusChangeButton category="save" label={'Aloita työ'} changeStatus={() => {setVisibleModal(true)}} />
            )
          }
          {cancelSos && (
            <StatusChangeButton
              category="cancelSecondary"
              label={'Peru työ'}
              changeStatus={() => {
                openRejectPage('cancel-sos')
              }}
            />
            )
          }
          {towingrecordCreated && createdTowingRecordId && <Redirect to={url} />}
          {visibleModal && (
            <ALTimeChangeModal
              label={`Työ aloitettu yli ${promptTime} min tilausajankohdasta`}
              onChangeOnlyReason={() => {
                changeStatus(client, CREATE_TOWING_RECORD_FROM_ORDER, { towingOrderId }, true)
                setVisibleModal(false)
              }}
              onClose={() => {
                setVisibleModal(false)
              }}
              isVisibleTimeInput={false}
              useQueryOnly={true}
              towingOrder={towingOrder}
              buttonSectionHeader="Anna syy:"
            />
          )}
        </BigButtonSection>
      )}
    </ApolloConsumer>
  )
}

function toSosMutationVariables(towingOrderId: number, user: Maybe<User>) {
  if (!user) {
    return {
      input: {
        id: towingOrderId,
        acceptedByDriverDetails: {
          driverName: '',
        },
      },
    }
  }

  return {
    input: {
      id: towingOrderId,
      acceptedByDriverDetails: {
        driverName: user.realName,
      },
    },
  }
}
