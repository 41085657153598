import { Maybe, User } from '../../common/types'
import { Item, ItemGroup, Preferences, Settings } from '../Parameters/types'
import {
  ActionTypes,
  HideSocketMessages,
  SetCurrentUserAction,
  SetItemAction,
  SetItemGroupAction,
  SetMessageCount,
  SetOfflineModeAction,
  SetOpenJobsCountAction,
  SetSettingsAction,
  SetUnSavedJobCount,
  SetUserPreferencesAction,
  ShowSocketMessages,
} from './types'

export function setItemGroups(itemGroups: Maybe<ItemGroup[]>): Maybe<SetItemGroupAction> {
  if (itemGroups) {
    return { type: ActionTypes.SET_ITEMGROUP, value: itemGroups }
  }
  return null
}

export function setItems(items: Maybe<Item[]>): Maybe<SetItemAction> {
  if (items) {
    return { type: ActionTypes.SET_ITEMS, value: items }
  }
  return null
}

export function setSettings(settings: Maybe<Settings>): Maybe<SetSettingsAction> {
  if (settings) {
    return { type: ActionTypes.SET_SETTINGS, value: settings }
  }
  return null
}

export function setOpenJobsCount(count: number | null): SetOpenJobsCountAction {
  return { type: ActionTypes.SET_JOBS_COUNT, value: count }
}

export function setCurrentUser(user: User): SetCurrentUserAction {
  return {
    type: ActionTypes.SET_CURRENT_USER,
    value: user,
  }
}

export function setOfflineMode(value: boolean): SetOfflineModeAction {
  return {
    type: ActionTypes.SET_OFFLINE_MODE,
    value,
  }
}

export function showSocketModal(): ShowSocketMessages {
  return {
    type: ActionTypes.SHOW_SOCKET_MESSAGES,
    value: true,
  }
}

export function hideSocketModal(): HideSocketMessages {
  return {
    type: ActionTypes.HIDE_SOCKET_MESSAGES,
    value: false,
  }
}

export function setUnSavedJobCount(count: number): SetUnSavedJobCount {
  const value: number = count < 0 ? 0 : count
  return {
    type: ActionTypes.SET_UN_SAVED_JOB_COUNT,
    value,
  }
}
export function setMessageCount(count: number): SetMessageCount {
  const value: number = count < 0 ? 0 : count
  return {
    type: ActionTypes.SET_MESSAGE_COUNT,
    value,
  }
}

// export function setArcCodes(arcCodes: Maybe<SosArcCodesFilters>): Maybe<SetUserPreferencesAction> {
//   if (arcCodes) {
//     return { type: ActionTypes.SET_ARC_CODES, value: arcCodes }
//   }
//   return null
// }

export function setUserPreferences(preferences: Maybe<Preferences>): Maybe<SetUserPreferencesAction> {
  if (preferences) {
    return { type: ActionTypes.SET_USER_PREFERENCES, value: preferences }
  }
  return null
}
